(function() {
    'use strict';

    angular.module('adminApp')
        .controller("ViewOrganizationCtrl", viewOrganizationController);

    viewOrganizationController.$inject = ["$scope", "$state", "aerosAdminApi", "$stateParams", "$uibModal",
        "userInvitationModal", "editUserInfoModal", "addLicenseAdminModal", "chooseLicenseAdminModal",
        "editOrgInfoAdminModal", "showLicenseUsersAdminModal", "Notification", "allRoles","OrganizationService",
        "routesConstant"];

    function viewOrganizationController($scope, $state, aerosAdminApi, $stateParams, $uibModal, userInvitationModal,
                                        editUserInfoModal, addLicenseAdminModal, chooseLicenseAdminModal,
                                        editOrgInfoAdminModal, showLicenseUsersAdminModal, Notification, allRoles,
                                        OrganizationService, routesConstant) {

        var ADMIN = $scope.ADMIN = routesConstant.ADMIN;

        $scope.roleNameById = {};
        $scope.roleRankById = {};
        $scope.rolesTooltip = '';

        _.each(allRoles.orgRoles, function (role) {
            $scope.roleNameById[role.id] = role.name;
            $scope.roleRankById[role.id] = role.rank;
            $scope.rolesTooltip += "• " + role.name + ": " + role.description + " ";
        });
        $scope.bestRankedId = function (roles) {
            var bestRank = 999;
            var bestRankedId = "";
            _.each(roles, function (role) {
                var roleLower = role.role.toLowerCase();
                var roleRank = $scope.roleRankById[role.role.toLowerCase()];
                if (roleRank < bestRank) {
                    bestRank = roleRank;
                    bestRankedId = roleLower;
                }
            });
            return bestRankedId;
        };

        loadOrganization();

        function loadOrganization() {
            aerosAdminApi.loadOrganization($stateParams.id).success(function (rsp) {
                var users = rsp.profile.users;
                var adminUsers = [];
                var orgUsers = [];
                var logUsers = [];
                $scope.adminUsers = adminUsers;
                $scope.orgUsers = orgUsers;
                $scope.logUsers = logUsers;
                $scope.org = rsp.profile.info;
                $scope.features = rsp.organizations.featureRefs;

                OrganizationService.vendorList().then(function(results) {
                    $scope.vendorName = OrganizationService
                        .getNameByVendorRef(rsp.organizations.vendorRef.key.raw.name,
                            results.data.vendors) ;
                });

                $scope.orgInfo = rsp.profile.contacts.location;
                $scope.orgInfo.vendorId = rsp.organizations.vendorRef.key.raw.name;
                $scope.orgContactInfo = rsp.profile.contacts.contact;
                $scope.licenses = rsp.profile.licenses;
                $scope.availableLicenses = [];
                $scope.AllLicenses = rsp.profile.licenses;
                $scope.ActiveLicenses =[];

                _.each(users, function (user) {
                    var result = $scope.licenses.filter(function (obj) {
                        return obj.id === user.licenseKey;
                    });

                    if (result.length) {
                        if (result[0].term === 'LifeTime') {
                            user.expiration = "Lifetime";
                        } else {
                            user.expiration = result[0].expirationDate;
                        }
                    }

                    user.searchIndex = user.firstName + " " + user.lastName + " " + user.email + " " + $scope.roleNameById[$scope.bestRankedId(user.roles.aerosRoles)] + " " + user.authStatus;
                    if (user.orgAdministrator || user.orgProjectManager) {
                        adminUsers.push(user);
                    } else {
                        orgUsers.push(user);
                    }
                    aerosAdminApi.loadOrganizationUserLogs($stateParams.id,user.username,1,0).success(function (data) {
                        if (data.length) {
                            logUsers.push(user);
                        };
                    });
                });

                _.each($scope.licenses, function (license) {
                    license.label = license.id + " (" + license.term + ")";
                    if ((!license.hasOwnProperty("assignedToAdmin")) && !(license.status==="EXPIRED")) {
                        $scope.availableLicenses.push(license);
                    }

                    if(!(license.status==="EXPIRED")){
                        $scope.ActiveLicenses.push(license);
                    }
                });
                $scope.page = {
                    title: "Organization: " + $scope.org.name
                };
                $scope.licenses= $scope.ActiveLicenses;
            });
        }

        // auto select org-admin role without allowing UI to change
        $scope.availableRoles = _.reject(allRoles.orgRoles, function (role) {
            return role.id !== "orgadmin";
        });
        $scope.selectedRoles = _.map($scope.availableRoles, function (role) {
            return role.id;
        });
        $scope.showRoleSelection = false;

        $scope.showEditUserInfoModal = function (orgUser, isInfo, title) {
            editUserInfoModal.open($scope.org, orgUser, isInfo, title, $scope.availableLicenses).then(function (rsp) {
                rsp.organizationId = $scope.org.id;
                aerosAdminApi.inviteUser(rsp, rsp.newLicenseModel.key)
                    .success(function () {
                        loadOrganization($scope.org.id);
                    })
                    .error(function (err) {
                        Notification.error("User not added. " + err.message);
                    });

            });
        };

        $scope.userInvitation = function (username, orgId) {
            aerosAdminApi.userInvitationEmail(username, orgId).then(
                function (response) {
                    Notification.success("Sent user activation email to " + username);
                },
                function (error) {
                    Notification.error("Error encountered sending user activation email to " + username);
                })
        };

        $scope.sendReset = function (username) {
            //var username = user.username;
            aerosAdminApi.sendResetEmail(username).then(
                function (response) {
                    Notification.success("Sent password reset email to " + username);
                },
                function (error) {
                    Notification.error("Error encountered sending password reset email to " + username);
                })
        };
        $scope.editOrgInfo = function (showOrgInfoDetails, administrationContactType) {
            var contactInfo = {};
            if (showOrgInfoDetails) {
                contactInfo = $scope.orgInfo;
            } else {
                contactInfo = $scope.orgContactInfo;
            }
            contactInfo.contactType = administrationContactType;
            editOrgInfoAdminModal.open($scope.org.id, contactInfo, showOrgInfoDetails).then(function () {
                loadOrganization($scope.org.id);
            });
        };

        $scope.showLicenseUsers = function (license) {
            showLicenseUsersAdminModal.open(license).then(function () {
                loadOrganization($scope.org.id);
            })
        };

        $scope.addNewLicense = function () {
            addLicenseAdminModal.open($scope.org, true).then(function (newLicense) {
                loadOrganization($scope.org.id);
            });
        };
        $scope.assignUser = function (user, admin) {
            var availLicenses = [];
            if (admin) {
                availLicenses = $scope.availableLicenses;
            } else {
                availLicenses = $scope.availableLicensesForOrgUsers;
            }
            chooseLicenseAdminModal.open(availLicenses).then(function (license) {
                aerosAdminApi.addUserToLicense($scope.org.id, license.id, user.username, user.roles.aerosRoles[0].role)
                    .success(function () {
                        loadOrganization($scope.org.id);
                        Notification.success("User (" + user.username + ") successfully added to licensekey (" + license.id + ")");
                    })
                    .error(function (err) {
                        Notification.error("User not added to licensekey (" + license.id + "). " + err.message);
                    });
            });
        };

        $scope.unAssignUser = function (user) {
            aerosAdminApi.removeUserFromLicense(user.licenseKey, user.username)
                .success(function () {
                    loadOrganization($scope.org.id);
                    Notification.success("User (" + user.username + ") successfully unassigned from licensekey (" + user.licenseKey + ")");
                })
                .error(function (err) {
                    Notification.error("User not unassigned. " + err.message);
                });
        };

        $scope.deleteUser = function (user) {
            $uibModal.open({
                size: "md",
                resolve: {
                    user: function () {
                        return user;
                    }
                },
                templateUrl: 'confirmDeleteModal.html',
                controller: ["$scope", "user", function ($scope, user) {
                    $scope.fullName = user.firstName + " " + user.lastName;
                }]
            }).result.then(function () {
                return aerosAdminApi.deleteUser(user.username, $scope.org.id)
                    .success(function (data) {
                        loadOrganization($scope.org.id);
                        Notification.success("User " + user.username + " deleted");
                    }).error(function (err) {
                        Notification.error("User " + user.username + " not deleted. " + err.message);
                    });
            });
        };

        $scope.addUserToAerosDebugging = function (user,orgId) {
            var duration = 1;
            aerosAdminApi.setOrganizationUserDebugging(orgId,user,duration).success(function (res) {
                if(res.debugSessionDurationInHours == duration) {
                    loadOrganization();
                }
            });
        };

        $scope.removeJsonLogging = function (user,orgId) {
            var duration = 0;
            aerosAdminApi.setOrganizationUserDebugging(orgId,user,duration).success(function (res) {
                if(res.debugSessionDurationInHours == duration) {
                    loadOrganization();
                }
            });
        };

        $scope.displayJsonLogs = function (userEmailParam,orgIdParam) {
            $state.go(ADMIN.USER_LOGGING.stateName, { orgId: orgIdParam, userEmail: userEmailParam } );
        };

        $scope.emailJsonLogs = function (user,orgId) {
            // call TBD back end service
        };

        $scope.showActiveLicenses = function() {
            $scope.licenses = $scope.ActiveLicenses;
        };

        $scope.showAllLicenses = function(){
            $scope.licenses = $scope.AllLicenses;
        };
    }

})();


